<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 p-3" v-if="$themeName() == 'platinum'">
          {{ new Date().getFullYear() }} © Platinum Credit Limited.
        </div>
        <div class="col-12 p-3" v-if="$themeName() == 'premier'">
          {{ new Date().getFullYear() }} © Premier Credit Limited.
        </div>
        <div class="col-12 p-3" v-if="$themeName() == 'spectrum'">
          {{ new Date().getFullYear() }} © Spectrum Credit Limited.
        </div>
        <div class="col-12 p-3" v-if="$themeName() == 'momentum'">
          {{ new Date().getFullYear() }} © Momentum Credit Limited.
        </div>
        <div class="col-12 p-3" v-if="$themeName() == 'fanikiwa'">
          {{ new Date().getFullYear() }} © Fanikiwa Micro Finance Limited.
        </div>
      </div>
    </div>
  </footer>
</template>
