import { createStore } from 'vuex';

//import modules from './modules';

const store = createStore({

    // Enable strict mode in development to get a warning
    // when mutating state outside of a mutation.
    // https://vuex.vuejs.org/guide/strict.html
    strict: process.env.NODE_ENV !== 'production',
    state: {
        isAuthenticated: false
    },
    mutations: {
        auth (state, value) {
            state.isAuthenticated = value.value;
        }
    }
});

export default store;
