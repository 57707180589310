<script>
import router from "@/router";

export default {
  props: {
    imgUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      subUrl: this.imgUrl,
    };
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="menu-bar">
        <div>
          <!--subUrl will be replaced by the current route and this will be used to switch the logos-->
          <span
            v-if="
              subUrl === 'https://premierkenya.co.ke/' ||
              subUrl === 'https://www.premiercredit.co.ug/' ||
              subUrl === 'http://www.premiercredit.co.za/'
            "
          >
            <a :href="subUrl" target="_blank">
              <img
                class="logo"
                src="../../assets/images/logos/premier-credit-sub.png"
                alt="Premier Credit logo"
              />
            </a>
          </span>
          <span v-if="subUrl === 'https://momentumcredit.co.ke/'">
            <a :href="subUrl" target="_blank">
              <img
                class="logo"
                src="../../assets/images/logos/momentum-credit-sub.png"
                alt="Momentum Credit Logo"
              />
            </a>
          </span>
          <span v-if="subUrl === 'https://www.spectrumcreditltd.com/'"
            >s
            <a :href="subUrl" target="_blank">
              <img
                class="logo"
                src="../../assets/images/logos/spectrum-credit-sub.png"
                alt="Spectrum Credit Logo"
              />
            </a>
          </span>
          <span v-if="subUrl === 'https://viva-365.com/'">
            <a :href="subUrl" target="_blank">
              <img
                class="logo"
                src="../../assets/images/logos/viva-sub.png"
                alt="Viva Sub logo"
              />
            </a>
          </span>
          <span v-if="subUrl === 'https://www.eezygroup.net/'">
            <a :href="subUrl" target="_blank">
              <img
                class="logo"
                src="../../assets/images/logos/ez-green-alt-sub.png"
                alt="Eezy Group Logo"
              />
            </a>
          </span>
          <span v-if="subUrl === 'https://fanikiwa.co.tz/'">
            <a :href="subUrl" target="_blank">
              <img
                class="logo"
                src="../../assets/images/logos/fanikiwa-sub.png"
                alt="Fanikiwa Sub logo"
              />
            </a>
          </span>

          <!-- PREVIOUS LOGO: <img src="@/assets/images/logo.png" class="logo"/> -->
        </div>
      </div>
    </div>

    <router-link to="/consent">Consent</router-link> |
    <router-link to="/thank_you">Thank you</router-link>
  </div>
</template>
