export default {
    install: (app) => {
        const { host } = window.location;

        app.config.globalProperties.$themeName = function () {
            if (host.toLowerCase().includes('platinum')) {
                return 'platinum';
            }
            else if (host.toLowerCase().includes('premiercredit.co.za')) {
                return 'premiersa';
            }
            else if (host.toLowerCase().includes('premier')) {
                return 'premier';
            }
            else if (
                host.toLowerCase().includes('eezytrack') ||
                host.toLowerCase().includes('ez') ||
                host.toLowerCase().includes('tracknav')
            ) {
                return 'eezytrack';
            }
            else if (host.toLowerCase().includes('momentum')) {
                return 'momentum';
            }
            else if (host.toLowerCase().includes('fanikiwa')) {
                return 'fanikiwa';
            }
            else if (host.toLowerCase().includes('spectrum')) {
                return 'spectrum';
            }
            else if (host.toLowerCase().includes('viva')) {
                return 'viva';
            }
            else {
                return 'platinum';
            }
            return 'phl';
        };
    },
};